import React from 'react';
import GameAds from './art/ads';

function MainPage() {
  return (
    <>
      <GameAds />
    </>
  );
}

export default MainPage;
